<template>
  <form @submit.prevent="handleSave" method="POST">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">
          
          <div class="col-span-6 sm:col-span-3">
            <label for="name" class="block text-sm font-medium text-gray-700">Name tag</label>
            <input type="text" name="name" id="name" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.name">
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="slug" class="block text-sm font-medium text-gray-700">Item slug (voor url)</label>
            <input type="text" name="slug" id="slug" autocomplete="slug" class="mt-1 focus:ring-orange-500 bg-gray-200 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.slug" readonly>
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
            <textarea name="description" id="description" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.description"></textarea>
          </div>

        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
          <svg v-show="loading" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
          {{loading ? 'saving': 'save'}}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";

import TagService from '../../../services/admin/tags/service';

export default {
  name: 'TagFormComponent',
  props: ['tag'],
  setup(props) {
    const loading = ref(true);
    loading.value = false;

    return {
      c: props.tag,
      loading
    }
  },
  methods: {
    async handleSave() {
      this.loading = true;
      TagService.save(this.tag);
      this.loading = false;
    }
  }
}
</script>
